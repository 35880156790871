<template>
	<a-card title="登录" class="panel-login">
		<a-form-model ref="form" :model="formdata" :rules="rules">
			<a-form-model-item prop="username">
				<a-input v-model="formdata.username" type="text" autocomplete="off" placeholder="请输入账号">
					<a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
				</a-input>
			</a-form-model-item>
			<a-form-model-item prop="password">
				<a-input-password v-model="formdata.password" autocomplete="off" placeholder="请输入密码">
					<a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
				</a-input-password>
			</a-form-model-item>
			<a-form-model-item>
				<a-button type="primary" @click="onSubmit" block>登录</a-button>
			</a-form-model-item>
		</a-form-model>
	</a-card>
</template>
<script>
	export default {
		data() {
			return {
				formdata: {
					username: "",
					password: ""
				},
				rules: {
					username: [{
						required: true,
						message: "请输入账号",
						trigger: "blur",
					}],
					password: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							min: 6,
							message: "请输入至少输入6位",
							trigger: "blur"
						},
					]
				},
			};
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						const response = await this.$api.post('/backstage_login', this.formdata);
						if (response && response.code == 200 && response.data.token) {
							const {
								token,
								...minfo
							} = response.data;
							minfo.isAdmin =  !/cangku/.test(minfo.userName)
							this.$storage.set("token", token);
							this.$storage.set("minfo", minfo);
							this.$store.dispatch("setToken", token);
							location.replace("/");
						} else {
							this.$message.error(response.data.msg);
						}
					}
				});
			},
		},
	};
</script>

<style lang="less">
	.panel-login {
		margin: 40px auto;
		width: 360px;
	}

	.form-meta {
		text-align: right;
	}
</style>
